
import { defineComponent, reactive, ref, toRefs, nextTick } from "vue";
import moment from "moment";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import Pagination from "@/components/Pagination.vue";
import useFetch from "@/hooks/useFetch";
import useTableHeight from "@/hooks/useTableHeight";
import {
  getSubscribedBu,
  searchReleaseBatch,
  getAllParentBu,
} from "@/API/approve";
import { message, Modal } from "ant-design-vue";
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";

import { QueryParams, ReleaseBatch } from "./types";
import { useI18n } from "vue-i18n";

type Key = ColumnProps["key"];

export default defineComponent({
  name: "Sales Measure",
  components: { Pagination },
  setup() {
    const { tableHeight } = useTableHeight();
    const isOpen = ref(false);
    const { t } = useI18n();

    //const allDealerVisiable = ref<boolean>(true);

    const columns = [
      {
        title: t("salesmeasure.bu"),
        dataIndex: "buName",
        key: "buName",
        align: "left",
      },
      {
        title: t("salesmeasure.batchname"),
        dataIndex: "releaseBatchName",
        key: "releaseBatchName",
        slots: { customRender: "batchName" },
      },
      {
        title: t("salesmeasure.programcount"),
        dataIndex: "programCount",
        key: "programCount",
      },
      {
        title: t("salesmeasure.releasedate"),
        dataIndex: "releaseDate",
        key: "releaseDate",
      },
    ];
    const handleCollapse = () => {
      isOpen.value = !isOpen.value;
      nextTick(() => {
        const tablePosition = useTableHeight(
          true,
          ".pagination-container",
          true
        );
        tableHeight.value = tablePosition.tableHeight.value;
      });
    };

    //query Params
    const queryParams = reactive<QueryParams>({
      bu: [],
      releaseBegin: "",
      releaseEnd: "",
      batchName: "",
      programName: "",
      programCode: "",
    });

    const { data, pagination, refreshFetch } = useFetch<ReleaseBatch[]>(
      queryParams,
      [],
      searchReleaseBatch,
      true
    );

    //查询bu options
    //const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);
    const { data: buOptions } = useFetch<BuItem[]>({}, [], getAllParentBu);
    console.log(buOptions);
    // const buList = ref<any[]>([]);
    // const allDealer = ref<boolean>(false);
    // const allDealerVisiable = (record: any) => {
    //   getSubscribedBu().then((res) => {
    //     buList.value = res;
    //   });
    //   allDealer.value = buList.value.includes(record.buName);
    // };

    //分页事件处理
    const handlerPaginationChange = (page: number): void => {
      pagination.page = page;
      refreshFetch();
    };

    const handlerShowSizeChange = (current: number, pageSize: number): void => {
      pagination.pageSize = pageSize;
      Object.assign(pagination, { page: 1, pageSize: pageSize });
      refreshFetch();
    };

    const handlerPeriodChange = (date: string[]): void => {
      queryParams.releaseBegin = date[0];
      queryParams.releaseEnd = date[1];
    };

    const handlerResetClick = (): void => {
      Object.assign(queryParams, {
        bu: [],
        releaseBegin: "",
        releaseEnd: "",
        batchName: "",
        programName: "",
        programCode: "",
      });
      refreshFetch(1);
    };

    const handlerSearchClick = (): void => {
      refreshFetch(1);
    };

    return {
      //query Params
      ...toRefs(queryParams),

      //table
      columns,
      data,
      tableHeight,
      pagination,
      // allDealerVisiable,
      // allDealer,
      //事件处理
      handlerPaginationChange,
      handlerShowSizeChange,
      handlerSearchClick,
      handlerResetClick,

      //搜索的options
      buOptions,

      // moment
      moment,
      isOpen,
      handleCollapse,
      handlerPeriodChange,
    };
  },
});
